import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SelectOption } from 'shared/types';
import { AutocompleteField } from 'shared/ui/AutocompleteField';
import { CheckboxField } from 'shared/ui/CheckboxField';
import { Col } from 'shared/ui/Col';
import { ExpandButton } from 'shared/ui/ExpandButton';
import { PhoneField } from 'shared/ui/PhoneField';
import { Row } from 'shared/ui/Row';
import { SelectChipField } from 'shared/ui/SelectChipField';
import { StyledTextField } from 'shared/ui/StyledTextField';
import { userTariffOptions } from '../model/consts';
import { AgencyUser } from '../model/types/agencyUserSchema';

interface AgencyUserEditFormProps {
  onToggleAdditionalData: (isShowAdditionalData: boolean) => void;
  isShowAdditionalData: boolean;
  agencyOptions?: SelectOption[];
  onAgencySearchChange?: (value: string) => void;
  isAgenciesSearching?: boolean;
}

export const AgencyUserEditForm: FC<AgencyUserEditFormProps> = ({
  onToggleAdditionalData,
  isShowAdditionalData,
  agencyOptions,
  onAgencySearchChange,
  isAgenciesSearching,
}) => {
  const { t } = useTranslation();
  const form = useFormContext<AgencyUser>();
  const agencyId = form.watch('agencyId');
  const { id } = form.getValues();

  return (
    <Col spacing={8}>
      <PhoneField name={'phone'} required />
      <Row
        equalsChildrenWidth
        sx={{
          flexDirection: { xs: 'column', sm: 'row' },
          gap: { xs: 8, sm: 5 },
        }}
      >
        <StyledTextField
          label={t('ui.common.firstName')}
          name="firstName"
          required
          maxLength={20}
          showCharacterHintAtRemaining={10}
        />
        <StyledTextField
          label={t('ui.common.lastName')}
          name="lastName"
          required
          maxLength={20}
          showCharacterHintAtRemaining={10}
        />
      </Row>
      <Row
        equalsChildrenWidth
        sx={{
          flexDirection: { xs: 'column', sm: 'row' },
          gap: { xs: 8, sm: 5 },
        }}
      >
        <StyledTextField
          label={t('ui.common.email')}
          name="email"
          required
          maxLength={50}
          showCharacterHintAtRemaining={10}
        />
        <StyledTextField
          label={t('user.form.label.position')}
          name="position"
          required
          maxLength={40}
          showCharacterHintAtRemaining={10}
        />
      </Row>
      <AutocompleteField
        name="agencyId"
        label={t('user.form.label.agency')}
        options={agencyOptions || []}
        onSearchChange={onAgencySearchChange}
        isOptionsLoading={isAgenciesSearching}
        sx={{ width: '100%' }}
      />
      <Col spacing={8}>
        <ExpandButton
          expanded={isShowAdditionalData}
          onClick={() => onToggleAdditionalData(!isShowAdditionalData)}
        >
          {t('user.form.label.additionalData')}
        </ExpandButton>
        {isShowAdditionalData && (
          <Col spacing={8}>
            <Row
              equalsChildrenWidth
              sx={{
                flexDirection: { xs: 'column', sm: 'row' },
                gap: { xs: 8, sm: 5 },
              }}
            >
              <StyledTextField
                label={t('ui.common.social.vk')}
                name="vk"
                maxLength={100}
                showCharacterHintAtRemaining={10}
              />
              <StyledTextField
                label={t('ui.common.social.ok')}
                name="ok"
                maxLength={100}
                showCharacterHintAtRemaining={10}
              />
            </Row>
            <Row
              equalsChildrenWidth
              sx={{
                flexDirection: { xs: 'column', sm: 'row' },
                gap: { xs: 8, sm: 5 },
              }}
            >
              <StyledTextField
                label={t('ui.common.social.facebook')}
                name="facebook"
                maxLength={100}
                showCharacterHintAtRemaining={10}
              />
              <StyledTextField
                label={t('ui.common.social.instagram')}
                name="instagram"
                maxLength={100}
                showCharacterHintAtRemaining={10}
              />
            </Row>
            <StyledTextField
              label={t('ui.common.social.youtube')}
              name="youtube"
              maxLength={100}
              showCharacterHintAtRemaining={10}
              sx={{ width: { xs: '100%', sm: '49%' } }}
            />
          </Col>
        )}
      </Col>
      {!agencyId && (
        <SelectChipField
          chipOptions={userTariffOptions}
          name="tariff"
          label={t('user.form.label.subscriptionDays')}
        />
      )}
      {agencyId && (
        <CheckboxField
          name="isAdmin"
          label={t('user.form.label.makeAgencyAdmin')}
        />
      )}
      {!id && (
        <StyledTextField
          label={t('ui.common.referralLink')}
          name="invitedBy"
          maxLength={60}
          showCharacterHintAtRemaining={10}
          sx={{ width: '100%' }}
        />
      )}
    </Col>
  );
};
