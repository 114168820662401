import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line boundaries/element-types
import { useVersionCheck } from 'entities/object';
import { getLabelByValue } from 'shared/helpers/getLabelByValue';
import { useSnackbar } from 'shared/hooks/useSnackbar';
import { SelectOption } from 'shared/types';
import { CardLayout } from 'shared/ui/CardLayout';
import { Col } from 'shared/ui/Col';
import { ImageUploadField } from 'shared/ui/ImageUploadField';
import { Row } from 'shared/ui/Row';
import { SelectField } from 'shared/ui/SelectField';
import { useFilteredAlbumDates } from '../lib/useFilteredAlbumDatesProps';
import {
  useCreateAlbumMutation,
  useUploadAlbumPhotoMutation,
} from '../model/api/AlbumService';
import { useGetAlbumTypesQuery } from '../model/api/AlbumTypesService';
import {
  Album,
  EditAlbum,
  editAlbumZod,
  albumPhotosZod,
} from '../model/types/albumSchema';

type EditAlbumCardProps = {
  objectAddedAlbums: Album[];
};

export const EditAlbumCard: FC<EditAlbumCardProps> = ({
  objectAddedAlbums,
}) => {
  const { t } = useTranslation();
  const { objectId } = useParams<{ objectId: string }>();
  const [showDate, setShowDate] = useState<boolean>(false);
  const [filteredAlbumTypeOptions, setFilteredAlbumTypeOptions] = useState<
    SelectOption[]
  >([]);
  const [isImageUploading, setIsImageUploading] = useState<boolean>(false);
  const [createAlbum, { isLoading: isAlbumCreating }] =
    useCreateAlbumMutation();
  const [uploadPhoto, { isLoading: isPhotoUploading }] =
    useUploadAlbumPhotoMutation();

  const { checkVersion, showVersionChangedPopup } = useVersionCheck(objectId);

  const { data: albumTypeOptions } = useGetAlbumTypesQuery();
  const { showSnackbar } = useSnackbar();

  const newAlbumForm = useForm<EditAlbum>({
    resolver: zodResolver(editAlbumZod),
    mode: 'onChange',
    defaultValues: {
      name: '',
      type: '',
      objectId: Number(objectId),
      year: '',
      month: '',
      order: 1,
    },
  });

  const newAlbumPhotosForm = useForm<{ photos: File[] }>({
    resolver: zodResolver(albumPhotosZod),
    mode: 'all',
    defaultValues: {
      photos: [],
    },
  });

  const { filteredMonthsOptions, filteredYearsOptions } = useFilteredAlbumDates(
    {
      objectAddedAlbums,
      watchedMonth: newAlbumForm.watch('month'),
      watchedYear: newAlbumForm.watch('year'),
    }
  );

  useEffect(() => {
    const subscription = newAlbumForm.watch((data, { type }) => {
      if (type && albumTypeOptions) {
        newAlbumForm.setValue(
          'name',
          getLabelByValue(albumTypeOptions, newAlbumForm.getValues('type'))
        );
      }
    });

    return () => subscription.unsubscribe();
  }, [albumTypeOptions, newAlbumForm, newAlbumForm.watch]);

  const handleSaveAlbum = async () => {
    const isVersionChanged = await checkVersion();

    if (!isVersionChanged) {
      try {
        const result = await createAlbum({
          ...newAlbumForm.getValues(),
          order: objectAddedAlbums.length + 1,
        }).unwrap();
        newAlbumForm.reset();
        return result;
      } catch (error) {
        console.error(error);
        showSnackbar();
        return null;
      }
    }
  };

  const handleUploadNewAlbumPhotos = async (albumId: string | number) => {
    const { photos } = newAlbumPhotosForm.getValues();
    photos.forEach(async (photo) => {
      const isVersionChanged = await checkVersion();

      if (!isVersionChanged) {
        try {
          const photoData = new FormData();
          photoData.append('photo', photo);
          await uploadPhoto({
            objectId,
            albumId,
            formData: photoData,
          }).unwrap();
        } catch (error) {
          const fetchError = error as FetchBaseQueryError;
          console.error(fetchError);
          if (fetchError.status === 422) {
            newAlbumPhotosForm.setError('photos', {
              message: t('ui.fileUploadField.errorOnlyPngOrJpgFormat'),
            });
          } else {
            newAlbumPhotosForm.setError('photos', {
              message: t('ui.fileUploadField.serverErrorMaxSize'),
            });
          }
        }
      }
    });
    newAlbumPhotosForm.reset();
    newAlbumPhotosForm.clearErrors();
  };

  const handleCreateAlbum = async () => {
    const isFormAlbumValid = await newAlbumForm.trigger();
    const isFormPhotosValid = await newAlbumPhotosForm.trigger();

    if (isFormAlbumValid && isFormPhotosValid) {
      const album = await handleSaveAlbum();
      if (album) {
        await handleUploadNewAlbumPhotos(album.id);
      }
    }
  };

  useEffect(() => {
    const usedTypes = objectAddedAlbums.map((album) => album.type);
    const updatedFilteredAlbumTypeOptions =
      albumTypeOptions
        ?.filter((option) => {
          return (
            option.value === '7' || !usedTypes.includes(option.value.toString())
          );
        })
        .sort((a, b) => a.label.localeCompare(b.label)) ?? [];

    setFilteredAlbumTypeOptions(updatedFilteredAlbumTypeOptions);
  }, [objectAddedAlbums, albumTypeOptions, newAlbumForm]);

  const watchedType = newAlbumForm.watch('type');
  useEffect(() => {
    if (watchedType === '7') {
      setShowDate(true);
    } else {
      newAlbumForm.resetField('month');
      newAlbumForm.resetField('year');
      setShowDate(false);
    }
  }, [newAlbumForm, watchedType]);

  const wideMode = objectAddedAlbums.length > 0;

  const formErrors = newAlbumForm.formState.errors.root?.message
    ? [newAlbumForm.formState.errors.root.message]
    : [];

  return (
    <CardLayout
      header={{ title: t('album.form.title') }}
      footer={{
        onOkClick: handleCreateAlbum,
        okLabel: t('ui.common.save'),
        okButtonColor: 'secondary',
        cancelLabel: t('ui.common.cancel'),
        onCancelClick: wideMode
          ? () => {
              newAlbumForm.reset();
              newAlbumPhotosForm.reset();
            }
          : undefined,
        okButtonLoading: isImageUploading || isAlbumCreating,
      }}
      maxWidth={wideMode ? '344px' : '600px'}
      variant="secondary"
      errors={formErrors}
    >
      {showVersionChangedPopup()}
      <FormProvider {...newAlbumForm}>
        <Col spacing={8}>
          {wideMode ? (
            <Col spacing={8} equalsChildrenWidth>
              <SelectField
                options={filteredAlbumTypeOptions}
                label={t('album.form.label.type')}
                required
                name={'type'}
              />
            </Col>
          ) : (
            <Row spacing={4} equalsChildrenWidth>
              <SelectField
                options={albumTypeOptions ?? []}
                label={t('album.form.label.type')}
                required
                name={'type'}
              />
            </Row>
          )}

          {showDate && (
            <Row spacing={4} equalsChildrenWidth>
              <SelectField
                options={filteredMonthsOptions}
                label={t('album.form.label.month')}
                required
                name={'month'}
              />
              <SelectField
                options={filteredYearsOptions}
                label={t('album.form.label.year')}
                required
                name={'year'}
              />
            </Row>
          )}
        </Col>
      </FormProvider>
      <FormProvider {...newAlbumPhotosForm}>
        <ImageUploadField
          description={t('album.form.uploadSubtitle')}
          multiple
          name="photos"
          onLoadingChange={setIsImageUploading}
        />
      </FormProvider>
    </CardLayout>
  );
};
