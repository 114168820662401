import { ChangeEvent, FC, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, CircularProgress } from '@mui/material';
import {
  Album,
  useUploadAlbumPhotoMutation,
  albumPhotosZod,
} from 'entities/album';
import { useVersionCheck } from 'entities/object';
import { useSnackbar } from 'shared/hooks/useSnackbar';
import { TextColor } from 'shared/theme/colors';
import { PhotoData } from 'shared/types';

const MAX_PHOTO_COUNT_IN_ALBUM = 30;
const MAX_PHOTO_SIZE = 10;

export type AddPhotoButtonProps = {
  album: Album;
  photosCount: number;
};

export const AddPhotoButton: FC<AddPhotoButtonProps> = ({
  album,
  photosCount,
}) => {
  const { t } = useTranslation();
  const { objectId } = useParams<{ objectId: string }>();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const form = useForm<{ photo: FormData }>({
    resolver: zodResolver(albumPhotosZod),
  });

  const { checkVersion, showVersionChangedPopup } = useVersionCheck(objectId);

  const [uploadAlbumPhoto, { isLoading: isUploading }] =
    useUploadAlbumPhotoMutation();
  const { showSnackbar } = useSnackbar();

  const onFileChange =
    (onChange: (...event: unknown[]) => void) =>
    async (event: ChangeEvent<HTMLInputElement>) => {
      const isVersionChanged = await checkVersion();

      if (!isVersionChanged) {
        if (event.target.files?.length) {
          const fileData = Array.from(event.target.files);

          if (photosCount + fileData.length > MAX_PHOTO_COUNT_IN_ALBUM) {
            showSnackbar({ message: 'album.form.error.photoCountMax30' });
            return;
          }

          const loadedFiles: Array<PhotoData> = [];
          for (let i = 0; i < fileData.length; i++) {
            const fileSizeMB = fileData[i].size / 1024 / 1024;

            if (fileSizeMB > MAX_PHOTO_SIZE) {
              showSnackbar({
                message: 'album.form.error.photoSizeMax10MB',
              });
              continue;
            }

            try {
              const formData = new FormData();
              formData.append('photo', fileData[i]);

              const loadedFile = await uploadAlbumPhoto({
                objectId,
                albumId: album.id,
                formData,
              }).unwrap();
              loadedFiles.push({
                id: loadedFile.id,
                path: loadedFile.path,
                place: loadedFile.place,
              });
            } catch (error) {
              const fetchError = error as FetchBaseQueryError;
              console.error(fetchError);
              if (fetchError.status === 422) {
                showSnackbar({
                  message: t('ui.fileUploadField.errorOnlyPngOrJpgFormat'),
                });
              } else {
                showSnackbar();
              }
            }
          }
          const oldPhotos = album.albumImages || [];
          const allPhotos = [...oldPhotos, ...loadedFiles];

          onChange(allPhotos);

          if (fileInputRef.current) {
            fileInputRef.current.value = '';
          }
        }
      }
    };

  const handleAddPhotoClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <Controller
      name={'photo'}
      control={form.control}
      render={({ field: { ref, ...rest }, fieldState: { error } }) => (
        <>
          {showVersionChangedPopup()}
          <Button
            onClick={handleAddPhotoClick}
            size="small"
            disabled={photosCount >= MAX_PHOTO_COUNT_IN_ALBUM || isUploading}
          >
            {t('album.addPhoto')}
            {isUploading && (
              <CircularProgress
                size={24}
                sx={{
                  color: TextColor.Secondary,
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
          </Button>
          <input
            ref={fileInputRef}
            type={'file'}
            accept={'.png,.jpg'}
            multiple
            style={{ display: 'none' }}
            onChange={onFileChange(rest.onChange)}
          />
        </>
      )}
    />
  );
};
