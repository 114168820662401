import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ServerErrorMessage } from 'entities/developer';
import {
  useCreateUserMutation,
  CreateUser,
  createUserZod,
  UserEditForm,
} from 'entities/user';
import { getRoute } from 'shared/const';
import { CardLayout } from 'shared/ui/CardLayout';
import { ResultCard } from 'shared/ui/ResultCard';

export const CreateUserCard: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isShowAdditionalData, setIsShowAdditionalData] =
    useState<boolean>(false);

  const [isUserCreatingError, setIsUserCreatingError] = useState(false);
  const [
    createUser,
    { isLoading: isUserLoading, isSuccess: isUserSuccessfullyCreated },
  ] = useCreateUserMutation();

  const form = useForm<CreateUser>({
    resolver: zodResolver(createUserZod),
    mode: 'onSubmit',
    defaultValues: {
      phone: '',
      firstName: '',
      lastName: '',
      email: '',
      position: '',
      tariff: null,
      isAdmin: false,
      agencyId: null,
      facebook: '',
      instagram: '',
      ok: '',
      vk: '',
      youtube: '',
      invitedBy: '',
    },
  });

  const handleCreateUser = async (userData: CreateUser) => {
    try {
      await createUser(userData).unwrap();
    } catch (error) {
      console.error(error);
      switch (error) {
        case 'ERROR_EMAIL_ALREADY_USED':
          form.setError('email', {
            message: ServerErrorMessage.ownerEmailInUse,
          });
          break;
        case 'ERROR_PHONE_ALREADY_USED':
          form.setError('phone', {
            message: ServerErrorMessage.ownerPhoneInUse,
          });
          break;
        default:
          setIsUserCreatingError(true);
          break;
      }
    }
  };

  useEffect(() => {
    const subscription = form.watch((value, { name }) => {
      if (name === 'agencyId') {
        if (!value.agencyId) {
          form.resetField('isAdmin');
        } else {
          form.resetField('tariff');
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [form]);

  if (isUserSuccessfullyCreated || isUserCreatingError) {
    return (
      <ResultCard
        isSuccess={isUserSuccessfullyCreated}
        successProps={{
          title: t('user.resultCard.success.title'),
          subtitle: t('user.resultCard.success.subtitle'),
          primaryButton: {
            navigateTo: getRoute.Users(),
          },
        }}
        errorProps={{
          title: t('user.resultCard.error.title'),
          primaryButton: {
            label: t('ui.common.returnToMain'),
            navigateTo: getRoute.Users(),
          },
        }}
      />
    );
  }

  return (
    <CardLayout
      header={{
        title: t('user.tab.button.addUser'),
        subtitle: t('user.create.card.subtitle'),
      }}
      footer={{
        onOkClick: form.handleSubmit(handleCreateUser),
        onCancelClick: () => navigate(getRoute.Users()),
        okLabel: t('ui.common.create'),
        okButtonColor: 'primary',
        cancelLabel: t('ui.common.close'),
        okButtonLoading: isUserLoading,
      }}
      maxWidth={648}
      marginAuto
      paddingY={24}
    >
      <FormProvider {...form}>
        <UserEditForm
          isShowAdditionalData={isShowAdditionalData}
          onToggleAdditionalData={setIsShowAdditionalData}
        />
      </FormProvider>
    </CardLayout>
  );
};
